const numberFormatWithPrefix = (number: number) => {
	number = Math.round(number);
	if (number < 1e3) {
		return number;
	}
	if (number >= 1e3 && number < 1e6) {
		return `${(number / 1e3).toFixed(0)}K`;
	}
	if (number >= 1e6 && number < 1e9) {
		return `${(number / 1e6).toFixed(0)}M`;
	}
	if (number >= 1e9 && number < 1e12) {
		return `${(number / 1e9).toFixed(0)}B`;
	}
	if (number >= 1e12) {
		return `${(number / 1e12).toFixed(0)}T`;
	}
};
export default numberFormatWithPrefix;
